<template>
  <div class="footer">
    <div class="footer-head" v-if="!isForm">
      <div data-v-bf47375a="">
        <div class="title">为企业打造一站式风险管理解决方案</div>
        <div class="button" @click="goForm">免费申请产品试用</div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-container">
        <el-collapse v-model="activeNames" :border="false">
          <el-collapse-item :border="false" v-for="(item, index) in footercollapse" :key="index" :title="item.title"
            :name="`${index + 1}`">
            <template v-if="index === 0">
              <div v-for="(li, idx) in item.list" :key="idx">
                <a class="detail" v-if="li.href" target="_blank" rel="external nofollow" :href="li.href">{{ li.text
                }}</a>
                <div v-else class="detail" @click="goForm">{{ li.text }}</div>
              </div>
            </template>
            <template v-else-if="index === 1">
              <div class="detail" v-for="(li, idx) in item.list" :key="idx" @click="goToXuexi(idx)">{{ li }}</div>
            </template>
            <template v-else-if="index === 2">
              <div class="detail" v-for="(li, idx) in item.list" :key="idx">{{ li }}</div>
            </template>
            <div class="qr-code" v-else>
              <div class="qr-code-list">
                <img src="@/images/newHome/footer-gzh.png" alt="" />
                <div class="detail">公众号</div>
              </div>
              <div class="qr-code-list">
                <img src="@/images/newHome/footer-vide.jpeg" alt="" />
                <div class="detail">视频号</div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="footer-bottom-logo"><img src="@/images/newHome/footer-logo.png" alt="" /></div>
      <div class="footer-bottom-line"></div>
      <div class="footer-bottom-filings">
        © 2024, iLaw Compiance Academy.<span style="cursor: pointer">
          京ICP备19014217号-11</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isForm: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activeNames: ['1'],
      footercollapse: [
        {
          title: '产品服务',
          list: [
            { text: "达芬奇·智能合同管理系统", href: 'https://contract.dafenqi.law/web-site' },
            { text: "达芬奇·智能案件管理系统", href: 'https://legal.dafenqi.law/web-site' },
            { text: "EyeTrust合规管理体系建设平台", href: 'https://hgb.heguixueyuan.com/' },
            { text: "LawTrust数据合规智能管理系统", href: 'https://lawtrust.cn/homePage' },
            { text: "企业级法律AI" },
          ],
        },
        {
          title: '关于我们',
          list: [
            '公司简介',
            '媒体资讯',
            '实务分享',
          ]
        },
        {
          title: '联系我们',
          list: [
            '北京：北京市朝阳区高碑店乡西店村49号楼四层4033',
            '深圳：广东省深圳市南山区滨海大道3398号赛西科技大厦',
            '郑州：河南自贸试验区郑州片区（郑东）东风南路绿地中心北塔51楼5101号',
            '海南：海南省陵水黎族自治县尔蔚街iLaw陵水学院'
          ]
        },
        {
          title: '订阅我们',
          list: ['公众号', '视频号']
        }
      ]
    }
  },
  methods: {
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
    goToXuexi(type) {
      const url = window.location.origin;
      if (type === 0) {
        window.open(`${url}/about`);
      } else if (type === 1) {
        window.open(`${url}/resourceCentre`);
      } else {
        window.open(`${url}/article?type=1`);
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/pages/css/mobile-base.scss";
@import "@/pages/css/mobile-home.scss";

.footer-head {
  height: px-to-vw(167);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/newHome/footer-background.png');
  background-size: cover
}

.footer-head .title {
  padding-top: px-to-vw(20);
  font-family: PingFang SC;
  font-size: px-to-vw(20);
  font-weight: 500;
  line-height: px-to-vw(31);
  color: #263b49
}

.footer-head .button {
  margin: px-to-vw(20) auto;
  margin-top: px-to-vw(15);
  width: px-to-vw(154);
  height: px-to-vw(42);
  border-radius: px-to-vw(24);
  background: #263b49;
  font-family: PingFang SC;
  font-size: px-to-vw(14);
  font-weight: 400;
  line-height: px-to-vw(42);
  text-align: center;
  color: #fff;
  cursor: pointer
}

.footer-bottom {
  padding: 40px 0 0;
  box-sizing: border-box;
  background: #101010;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5)
}

.footer-bottom .title {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff
}

.footer-bottom-container {
  margin: 0 auto;
  display: flex;
  max-width: 100%;
  height: 100%;
  padding: 0 20px 0;

  ::v-deep .el-collapse {
    width: 100%;
    background-color: #101010;
    border-top: none;
    border-bottom: none;
  }

  ::v-deep .el-collapse-item {
    margin-bottom: 20px;
  }

  ::v-deep .el-collapse-item__header {
    background-color: #1D1D1D;
    color: #ffffff;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: px-to-vw(14);
    line-height: px-to-vw(24);
    padding-left: px-to-vw(20);
    border-bottom: none;
    height: px-to-vw(48);
    border-radius: px-to-vw(4);
  }

  ::v-deep .el-collapse-item__wrap {
    background-color: #2A2A2A;
    border-bottom: none;
  }

  ::v-deep .el-collapse-item__content {
    padding-top: px-to-vw(10);

    .detail {
      display: block;
      font-family: PingFang SC;
      font-weight: 400;
      text-align: left;
      font-size: px-to-vw(12);
      line-height: px-to-vw(22);
      margin-top: px-to-vw(10);
      margin-left: px-to-vw(20);
      margin-right: px-to-vw(20);
    }
  }
}

.footer-bottom-container {
  .detail {
    font-family: PingFang SC;
    cursor: pointer;
    font-size: px-to-vw(12);
    font-weight: 400;
    line-height: 32px;
    color: #777e83;
  }

  .qr-code {
    margin: px-to-vw(20) 0;
    display: flex;
    text-align: center;
    justify-content: space-around;

    img {
      width: px-to-vw(80);
      height: px-to-vw(80);
    }
  }
}

.footer-bottom-logo {
  padding: 20px;
  text-align: center;
  background-color: #101010;

  img {
    width: 100px;
    height: 20px;
  }
}

.footer-bottom .footer-bottom-line {
  margin: 0 auto;
  width: 100%;
  height: 2px;
  background: #292929
}

.footer-bottom-filings {
  margin: 0 auto;
  height: px-to-vw(40);
  font-family: PingFang SC;
  font-size: px-to-vw(12);
  font-weight: 400;
  line-height: 15.6px;
  color: #a2a2a2;
  box-sizing: border-box;
  background: #101010;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>