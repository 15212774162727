<style scoped lang="scss">
@import "@/pages/css/home.scss";

@media (max-width: 768px) {
  @import "@/pages/css/mobile-home.scss";

  .home-content {
    width: 100%;
    height: px-to-vw(650);
    // background-image: url("../images/newHome/home-background.png");
    background-image: url("../images/newHome/mobile-home-background.jpeg");
    background-size: cover;
  }

  .m-left {
    background-image: url("../images/newHome/home-list-bg.jpeg");
    background-size: 100% 100%;
  }

  .home-matrix-item {
    &.blue {
      background-image: url("../images/newHome/home-blue.png");
      background-size: 100% 100%;
    }

    &.green {
      background-image: url("../images/newHome/home-green.png");
      background-size: 100% 100%;
    }

    &.puper {
      background-image: url("../images/newHome/home-puper.png");
      background-size: 100% 100%;
    }
  }
}
</style>
<template>
  <div class="home">
    <carouselCom />
    <div class="home-head">
      <div class="home-head-programme">
        <h1 class="home-head-programme-head" style="margin-bottom: 20px">
          企业风险治理和合规解决方案
        </h1>
        <div class="home-head-programme-container">
          <div class="home-head-programme-container-card">
            <div class="item" v-for="(item, index) of programmeList" :key="index">
              <img :src="item.img" :alt="item.alt" class="icon" />
              <h3 class="title test">{{ item.title }}</h3>
              <div class="txt">{{ item.txt }}</div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="isMobile">
        <div class="home-matrix home-matrix1">
          <div class="home-matrix-title">
            法律合规数字化产品矩阵
          </div>
          <div class="home-matrix-card">
            <div v-for="(partent, index) in partentOneList" :key="index">
              <div class="home-matrix-sub-title" :class="partent.color">
                {{ partent.title }}
              </div>
              <div class="home-matrix-item" :class="[partent.color]">
                <span :class="['card-radius']">
                  <i :class="[
                    { 'card-center': index == partentOneList.length - 1 },
                  ]" v-for="(list, idx) in partent.list" :key="idx">{{ list }}</i>
                  <i class="card-center">更多...</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="home-matrix home-matrix2">
          <div class="home-matrix-title home-matrix2-title">
            智能应用底座
          </div>
          <img class="home-matrix-title-img" src="@/images/newHome/home3-bg.png" alt="" />
          <div class="home3-text">
            <div>法律大数据</div>
            <div>AI赋能</div>
          </div>
          <div class="home-matrix-card2">
            <div class="home-matrix-card2-list" v-for="(partent, index) in partentTwoList" :key="index">
              <div class="home-matrix-card2-item">
                <div class="home-matrix-sub-title">{{ partent.title }}</div>
                <span :class="['card-radius']">
                  <i v-for="(list, idx) in partent.list" :key="idx">{{
                    list
                  }}</i>
                  <i>更多...</i>
                </span>
              </div>
              <img v-if="index == 0" class="exchange-img" src="@/images/newHome/exchange.png" alt="" />
            </div>
          </div>
        </div>
      </template>
      <img v-else src="../images/newHome/home2.png"
        alt="一体化法律合规解决方案，为企业提供法律+技术的全新合规咨询服务体验，方案涵盖全业务场景的产品化解决方案，支持协同更多律所与企业参编行业标准落地。产品由案例库、法规库、案例库、司法观点库、类案同判库等法律大数据为智能底座，由AI合同审查、AI合规监管时态研报、合规业务咨询、AI案情分析、AI文书写作为功能的企业级法律AI赋能。其中，达芬奇法务综合平台涵盖知识产权管理、案件纠纷管理、法务课堂。LawTrust专项合规治理平台包含数据合规、财务合规、劳动用功合规、广告营销合、ESG，EyeTrust合规管理体系建设平台包括云尽调、云支持、云诊断等。"
        class="picture picture-too" />

      <template v-if="isMobile">
        <div class="home-matrix home-matrix3">
          <div class="home-matrix-title">全流程企业风险管理</div>
          <div class="home-matrix3-list">
            <div class="home-matrix3-card">
              <div class="home-matrix3-card-item">
                <div class="icon-wrap">
                  <img src="../images/newHome/yf-icon.png" alt="" class="icon" />
                </div>
                <div class="title">预防</div>
              </div>
              <div class="arrow">
                <img src="../images/newHome/icon-double-right.svg" alt="" />
              </div>
            </div>
            <div class="home-matrix3-card">
              <div class="home-matrix3-card-item">
                <div class="icon-wrap">
                  <img src="../images/newHome/gl-icon.png" alt="" class="icon" />
                </div>
                <div class="title">管理</div>
              </div>
              <div class="arrow">
                <img src="../images/newHome/icon-double-right.svg" alt="" />
              </div>
            </div>
            <div class="home-matrix3-card">
              <div class="home-matrix3-card-item">
                <div class="icon-wrap">
                  <img src="../images/newHome/xy-icon.png" alt="" class="icon" />
                </div>
                <div class="title">响应</div>
              </div>
              <div class="arrow">
                <img src="../images/newHome/icon-double-right.svg" alt="" />
              </div>
            </div>
            <div class="home-matrix3-card">
              <div class="home-matrix3-card-item">
                <div class="icon-wrap">
                  <img src="../images/newHome/yh-icon.png" alt="" class="icon" />
                </div>
                <div class="title">优化</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="home-head-manage">
          <div class="home-head-manage-card">
            <h2 class="home-head-manage-card-head">全流程企业风险管理</h2>
            <div class="home-head-manage-card-content-wrap">
              <div class="home-head-manage-card-content" v-for="item of manageList" :key="item.type">
                <div class="home-head-manage-card-content-item">
                  <div class="icon-wrap">
                    <img :src="item.img" alt="" class="icon" />
                  </div>
                  <div class="title">{{ item.title }}</div>
                </div>
                <div class="arrow" v-if="item.type !== 4">
                  <div class="title">{{ item.txt }}</div>
                  <img src="../images/newHome/gl-arrow.png" alt="" class="arrow-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="home-content">
      <template v-if="isMobile">
        <div class="home-content-head-mobile">
          <div class="txt" style="margin-bottom: 16px">为什么选择iLaw？</div>
          <div class="txt-click">了解更多我们的优势</div>
          <div class="home-content-container-title">iLaw核心能力</div>
        </div>
        <div class="m-home-content-container">
          <div class="m-container-core">
            <div class="m-container-core-card">
              <img src="../images/newHome/home-core1.png" alt="" class="m-icon" />
              <div class="m-title">完善的产品体系</div>
              <div class="m-detail">
                四大合规体系，超过30个合规产品方案，为企业打造完善的合规产品体系
              </div>
            </div>
            <div class="m-container-core-card">
              <img src="../images/newHome/home-core2.png" alt="" class="m-icon" />
              <div class="m-title">丰富的实践经验</div>
              <div class="m-detail">
                超过300家企业法务一起探讨落地的合规方案，多家厂商隐私合规方案指定供应商
              </div>
            </div>
            <div class="m-container-core-card">
              <img src="../images/newHome/home-core3.png" alt="" class="m-icon" />
              <div class="m-title">前沿的监管洞察能力</div>
              <div class="m-detail">
                依托合规大数据能力制定合规策略，法规更新推送、风险预警，为企业赋能洞察监管趋势
              </div>
            </div>
            <div class="m-container-core-card">
              <img src="../images/newHome/home-core4.png" alt="" class="m-icon" />
              <div class="m-title">丰富的模板内容</div>
              <div class="m-detail">
                提供大量实践过的合规义务清单、评估问卷、隐私协议等模板，带来合规质量和业务效率的加倍提升
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="home-content-head">
          <h2 class="txt" style="margin-bottom: 16px">为什么选择iLaw？</h2>
          <h3 class="txt-click">
            了解更多我们的优势
            <!-- <img src="../images/newHome/home-arrow.png" alt="" class="arrow" /> -->
          </h3>
        </div>
        <div class="home-content-container">
          <h2 class="home-content-container-title">iLaw核心能力</h2>
          <div class="container-core">
            <div class="container-core-card" v-for="(item, index) of coreList" :key="index"
              @mouseenter="handleEnter(item)" @mouseleave="handleLeave(item)">
              <template v-if="isCreate && item.isHover">
                <div class="hover-content-header-create">
                  <img :src="item.icon" alt="" class="icon-create" />
                  <div class="title-create">{{ item.title }}</div>
                </div>
                <div class="hover-content-detail-create">
                  {{ item.detail }}
                </div>
              </template>
              <template v-else>
                <div class="hover-content-header">
                  <img :src="item.icon" alt="" class="icon" />
                  <div class="title">{{ item.title }}</div>
                </div>
                <div class="hover-content-detail" :style="{ opacity: item.isHover ? 1 : 0 }">
                  {{ item.detail }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isMobile">
        <div class="m-home-content-area">
          <div class="m-title">
            <p>丰富的合规解决方案</p>
            <p>覆盖多个行业领域</p>
          </div>
          <div class="m-container">
            <div class="m-left">
              <div class="m-left-head">
                <div class="m-left-head-txt">
                  <div class="m-area-title">了解您所在的行业解决方案</div>
                  <div class="m-area-detail">
                    提供适用于不同行业的定制化合规解决方案，满足不同行业领域、不同企业的差异化需求
                  </div>
                </div>
                <div class="m-left-head-button" @click="goForm">
                  立即咨询
                  <img src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-arrow-right.png" alt=""
                    class="icon" />
                </div>
              </div>
              <div class="m-scroll-container">
                <div class="m-scroll-item" style="margin-left: 10px">
                  <div class="m-left-prod-scroll m-xscroll m-xscroll50">
                    <img class="m-scroll-img" :src="item" alt="" v-for="(item, index) in imgList" :key="index" />
                  </div>
                  <div class="m-left-prod-scroll m-xscroll m-xscroll50">
                    <img class="m-scroll-img" :src="item" alt="" v-for="(item, index) in imgList" :key="index" />
                  </div>
                </div>
                <div class="m-scroll-item">
                  <div class="m-left-prod-scroll m-xscroll m-xscroll40">
                    <img class="m-scroll-img" :src="item" alt="" v-for="(item, index) in imgList2" :key="index" />
                  </div>
                  <div class="m-left-prod-scroll m-xscroll m-xscroll40">
                    <img class="m-scroll-img" :src="item" alt="" v-for="(item, index) in imgList2" :key="index" />
                  </div>
                </div>
                <div class="m-scroll-item">
                  <div class="m-left-prod-scroll m-xscroll m-xscroll50">
                    <img class="m-scroll-img" :src="item" alt="" v-for="(item, index) in imgList3" :key="index" />
                  </div>
                  <div class="m-left-prod-scroll m-xscroll m-xscroll50">
                    <img class="m-scroll-img" :src="item" alt="" v-for="(item, index) in imgList3" :key="index" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="home-content-area">
          <h2 class="title">丰富的行业解决方案，覆盖多个行业领域</h2>
          <div class="container">
            <div class="left">
              <div class="left-head">
                <div class="left-head-txt">
                  <div class="area-title">了解您所在的行业解决方案</div>
                  <div class="area-detail">
                    提供适用于不同行业的定制化解决方案，满足
                  </div>
                  <div class="area-detail">
                    不同行业领域、不同企业的差异化需求
                  </div>
                </div>
                <div class="left-head-button" @click="goForm">
                  立即咨询
                  <img src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-arrow-right.png" alt=""
                    class="icon" />
                </div>
              </div>
              <div class="scroll-container">
                <div class="scroll-item" style="margin-left: 10px">
                  <div class="left-prod-scroll xscroll xscroll50">
                    <img class="scroll-img" :src="item" alt="" v-for="(item, index) in imgList" :key="index" />
                  </div>
                  <div class="left-prod-scroll xscroll xscroll50">
                    <img class="scroll-img" :src="item" alt="" v-for="(item, index) in imgList" :key="index" />
                  </div>
                </div>
                <div class="scroll-item">
                  <div class="left-prod-scroll xscroll xscroll40">
                    <img class="scroll-img" :src="item" alt="" v-for="(item, index) in imgList2" :key="index" />
                  </div>
                  <div class="left-prod-scroll xscroll xscroll40">
                    <img class="scroll-img" :src="item" alt="" v-for="(item, index) in imgList2" :key="index" />
                  </div>
                </div>
                <div class="scroll-item">
                  <div class="left-prod-scroll xscroll xscroll50">
                    <img class="scroll-img" :src="item" alt="" v-for="(item, index) in imgList3" :key="index" />
                  </div>
                  <div class="left-prod-scroll xscroll xscroll50">
                    <img class="scroll-img" :src="item" alt="" v-for="(item, index) in imgList3" :key="index" />
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="area-picture" v-for="(item, index) of areaPicture" :key="index" @mouseover="item.hover = true"
                @mouseleave="item.hover = false">
                <span class="area-picture-title" :style="{
                  top: `${item.top}`,
                  left: `${item.left}`,
                  transform: item.hover ? 'scale(1.2)' : 'scale(1)',
                }">{{ item.label }}</span>
                <img :src="item.img" alt="" class="picture" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isMobile">
        <div class="m-home-content-qualifications">
          <div class="m-home-content-qualifications-head">
            <div class="m-title">荣誉资质</div>
            <div class="m-detail">
              凭借追求卓越、不断创新的精神，荣获多项荣誉资质
            </div>
          </div>

          <div class="m-home-content-qualifications-content">
            <img src="../images/newHome/home5.png" alt="" class="m-picture m-picture-four" />
            <div class="m-title">
              <p>iLaw凭借卓越的专业精神和不断的创新追求</p>
              <p>荣获多项荣誉资质</p>
            </div>
            <div class="m-lists">
              <div class="m-list" v-for="(item, index) in qualificationsList" :key="index">
                {{ `${index + 1}、${item}` }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="home-content-qualifications">
          <div class="home-content-qualifications-head">
            <h2 class="title">荣誉资质</h2>
            <div class="detail">
              凭借追求卓越、不断创新的精神，荣获多项荣誉资质
            </div>
          </div>
          <img src="../images/newHome/home4.png" alt="" class="picture picture-four" />
        </div>
      </template>
      <div class="qualifications-scroll">
        <div class="scroll-item">
          <div class="xscroll xscroll200">
            <img class="scroll-img" :src="item.img" alt="" v-for="(item, index) in honorList" :key="index"
              :style="{ width: `${item.width}` }" />
          </div>
          <div class="xscroll xscroll200">
            <img class="scroll-img" :style="{ width: `${item.width}` }" :src="item.img" alt=""
              v-for="(item, index) in honorList" :key="index" />
          </div>
        </div>
      </div>

      <MobileFooter v-if="isMobile" />
      <footerCom v-else></footerCom>

      <reservationCom></reservationCom>
    </div>
  </div>
</template>

<script>
import carouselCom from "../components/carouselCom/index";
import footerCom from "../components/footerCom/index";
import reservationCom from "../components/reservationCom/index";
import MobileFooter from "../components/mobile-footer/index";
import { throttle } from "lodash";
export default {
  name: "Home",
  metaInfo: {
    title: "iLaw官网_合规系统_合规风险管理_一站式法律合规风险治理解决方案",
    meta: [
      {
        name: "keywords",
        content:
          "iLaw,合规管理系统,合规分险治理,法律合规,法律大数据,合同系统,案件系统,合同智能审查,法律AI",
      },
      {
        name: "description",
        content:
          "iLaw合规。经过近十年的努力，iLaw核心研发产品有：LawTrust数据合规管理系统，有效支撑企业数据隐私合规落地；达芬奇·Eyetrust合规眼系统，对齐最新ISO37301国际合规标准，助力企业开展合规建设；达芬奇·智能案件管理系统，提高企业涉案管理效率与透明度；达芬奇·智能合同管理系统，高效赋能企业合同管理全流程。公司旨在帮助企业法务部门加速数字化建设，助力企业合规经营。",
      },
    ],
  },
  components: {
    carouselCom,
    footerCom,
    reservationCom,
    MobileFooter,
  },
  data() {
    const zte = require("../images/newHome/zte.png");
    const zl = require("../images/newHome/zl.png");
    const zjfd = require("../images/newHome/zjfd.png");
    const ybx = require("../images/newHome/ybx.png");
    const xyd = require("../images/newHome/xyd.png");
    const tx = require("../images/newHome/tx.png");
    const ts = require("../images/newHome/ts.png");
    const tl = require("../images/newHome/tl.png");
    const sqhy = require("../images/newHome/sqhy.png");
    const qw = require("../images/newHome/qw.png");
    const msxf = require("../images/newHome/msxf.png");
    const mindray = require("../images/newHome/mindray.png");
    const lz = require("../images/newHome/lz.png");
    const joyy = require("../images/newHome/joyy.png");
    const hw = require("../images/newHome/hw.png");
    const gtzm = require("../images/newHome/gtzm.png");
    const fc = require("../images/newHome/fc.png");
    const dfhy = require("../images/newHome/dfhy.png");
    const chnt = require("../images/newHome/chnt.png");
    const bk = require("../images/newHome/bk.png");
    const bjdh = require("../images/newHome/bjdh.png");
    const bc = require("../images/newHome/bc.png");
    const albb = require("../images/newHome/albb.png");
    const jyzy = require("../images/newHome/jyzy.png");
    return {
      isMobile: false,
      coreList: [
        {
          title: "完善的产品体系",
          detail:
            "四大产品体系，超过30个合规产品方案，为企业打造完善的产品体系",
          icon:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-core1.png",
          isHover: true,
          id: 1,
          alt: "四大产品体系,超过30个合规产品方案，为企业打造完善的产品体系",
        },
        {
          title: "丰富的实践经验",
          detail:
            "超过300家企业法务一起探讨落地的解决方案，多家厂商隐私解决方案指定供应商",
          icon:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-core2.png",
          iconHover:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-core2-hover.png",
          isHover: false,
          id: 2,
          alt: "丰富的实践经验",
        },
        {
          title: "前沿的监管洞察能力",
          detail:
            "依托合规大数据能力制定合规策略，法规更新推送、风险预警，为企业赋能洞察监管趋势",
          icon:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-core3.png",
          isHover: false,
          id: 3,
          alt: "前沿的监管洞察能力",
        },
        {
          title: "丰富的模板内容",
          detail:
            "提供大量实践过的合规义务清单、评估问卷、隐私协议等模板，带来合规质量和业务效率的加倍提升",
          icon:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/home-core4.png",
          isHover: false,
          id: 4,
          alt: "丰富的合规模板内容",
        },
      ],
      imgList: [zte, zl, zjfd, ybx, xyd, tx, ts, tl],
      imgList2: [sqhy, qw, msxf, mindray, lz, joyy, hw, jyzy],
      imgList3: [gtzm, fc, dfhy, chnt, bk, bjdh, bc, albb],

      areaPicture: [
        {
          label: "互联网",
          top: "70px",
          left: "50px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/hlw.png",
          hover: false,
        },
        {
          label: "金融",
          top: "70px",
          left: "62px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/jr.png",
          hover: false,
        },
        {
          label: "信息技术",
          top: "70px",
          left: "35px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/xxjs.png",
          hover: false,
        },
        {
          label: "能源",
          top: "70px",
          left: "62px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/ny.png",
          hover: false,
        },
        {
          label: "建筑",
          top: "70px",
          left: "62px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/jz.png",
          hover: false,
        },
        {
          label: "交通运输",
          top: "70px",
          left: "35px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/jtys.png",
          hover: false,
        },
        {
          label: "快消",
          top: "70px",
          left: "62px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/kx.png",
          hover: false,
        },
        {
          label: "更多",
          top: "70px",
          left: "62px",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/gd.png",
          hover: false,
        },
      ],
      honorList: [
        { img: require("../images/newHome/honor1.png"), width: "345px" },
        { img: require("../images/newHome/honor2.png"), width: "170px" },
        { img: require("../images/newHome/honor3.png"), width: "345px" },
        { img: require("../images/newHome/honor4.png"), width: "170px" },
        { img: require("../images/newHome/honor5.png"), width: "170px" },
        { img: require("../images/newHome/honor6.png"), width: "345px" },
        { img: require("../images/newHome/honor7.png"), width: "345px" },
        { img: require("../images/newHome/honor8.png"), width: "345px" },
        { img: require("../images/newHome/honor9.png"), width: "170px" },
        { img: require("../images/newHome/honor10.png"), width: "170px" },
        { img: require("../images/newHome/honor11.png"), width: "170px" },
        { img: require("../images/newHome/honor12.png"), width: "170px" },
        { img: require("../images/newHome/honor13.png"), width: "170px" },
        { img: require("../images/newHome/honor14.png"), width: "170px" },
        { img: require("../images/newHome/honor15.png"), width: "170px" },
        { img: require("../images/newHome/honor16.png"), width: "170px" },
        { img: require("../images/newHome/honor17.png"), width: "170px" },
        { img: require("../images/newHome/honor18.png"), width: "170px" },
        { img: require("../images/newHome/honor19.png"), width: "170px" },
        { img: require("../images/newHome/honor20.png"), width: "170px" },
      ],
      programmeList: [
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/zx-icon.png",
          title: "咨询服务",
          txt: "法律+技术的全新咨询服务体验",
          alt: "法律+技术的全新合规咨询服务体验",
        },
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/jjfa-icon.png",
          title: "智能化解决方案落地",
          txt: "涵盖全业务场景的产品化解决方案",
          alt: "一站式智能化解决方案",
        },
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/rz-icon.png",
          title: "企业认证",
          txt: "为企业认证提供有力支撑",
          alt: "为企业认证提供有力支撑",
        },
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/bz-icon.png",
          title: "引领行业标准",
          txt: "支持协同更多律所与企业参编行业标准落地",
          alt: "支持协同更多律所与企业参编行业标准落地",
        },
      ],
      manageList: [
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/yf-icon.png",
          title: "预防",
          txt: "风险管理基础设施",
          type: 1,
        },
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/gl-icon.png",
          title: "管理",
          txt: "智能决策引擎",
          type: 2,
        },
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/xy-icon.png",
          title: "响应",
          txt: "持续循环优化",
          type: 3,
        },
        {
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/home/yh-icon.png",
          title: "优化",
          type: 4,
        },
      ],
      partentOneList: [
        {
          title: "达芬奇法务综合平台",
          color: "blue",
          list: [
            "合同全生命周期管理",
            "案件纠纷管理",
            "知产管理",
            "制度管理",
            "证照管理",
            "法务课堂",
            "授权委托管理",
            "函件文书管理",
          ],
        },
        {
          title: "LawTrust专项风险治理平台",
          color: "green",
          list: [
            "反垄断&反不当竞争合规",
            "劳动用工合规",
            "数据合规",
            "财税合规",
            "产品质量",
            "碳中和与ESG服务",
            "反腐败&反商业贿赂",
            "知识产权合规",
          ],
        },
        {
          title: "EyeTrust企业管理体系建设平台",
          color: "puper",
          list: [
            "云尽调",
            "云支持",
            "云诊断",
            "云评估",
            "云监控",
            "云指数",
            "云培训",
          ],
        },
      ],
      partentTwoList: [
        {
          title: "法律大数据",
          list: [
            "案例库",
            "法规库",
            "行政处罚库",
            "实务文章库",
            "司法观点库",
            "优案评析库",
            "公司主体库",
            "期刊论文库",
          ],
        },
        {
          title: "企业级法律AI",
          list: [
            "AI合同审查",
            "AI合同履约",
            "AI合规监管态势情报",
            "AI案情策略分析",
            "AI业务审核",
            "AI智能检索",
            "AI合规业务咨询",
            "AI文书写作",
          ],
        },
      ],
      qualificationsList: [
        "荣登2018中国大数据创新企业TOP100",
        "入选信通院2022年首批「审护计划」成员",
        "参与《数据合规管理体系要求》团体标准起草",
        "作为国家市监局认研中心企业合规师认证（ISO37301）官方培训软件供应商",
        "作为DCMM 数据合规师(DCO)培训教材的参编单位",
        "荣获“中国互联网创新大赛企业合规赛道优秀奖”",
        "荣获“第十五届中国商业联合会服务业科技创新奖”",
      ],
      isCreate: true,
    };
  },
  mounted() {
    this.getPicture();
    const throttleGetPicture = throttle(this.getPicture, 500);
    window.addEventListener("resize", throttleGetPicture);
  },
  methods: {
    handleEnter(item) {
      this.isCreate = false;
      this.coreList.forEach((el) => {
        el.isHover = false;
      });
      item.isHover = true;
    },
    handleLeave(item) {
      item.isHover = false;
    },
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
    getPicture() {
      if (window.matchMedia("(max-width: 768px)").matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
