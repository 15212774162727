import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index'
import Home from '../pages/Home'
import About from '../pages/About'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/about',
        name: 'About',
        component: About,
      },

      {
        path: '/selectedVideo',
        name: 'SelectedVideo',
        component: () => import('../pages/selectedVideo/index'),
      },
      {
        path: '/resourceCentre',
        name: 'ResourceCentre',
        component: () => import('../pages/resourceCentre/index'),
      },
      {
        path: '/case',
        name: 'Case',
        component: () => import('../pages/case/index'),
      },
      {
        path: '/article',
        name: 'Article',
        component: () => import('../pages/article/index'),
      },
      {
        path: '/appointment',
        name: 'Appointment',
        component: () => import('../pages/appointment/index'),
      },
      {
        path: '/mobilePoduct',
        name: 'MobileProduct',
        component: () => import('../pages/mobile-product/index'),
      }
    ]
  },

  {
    path: '/document-privacy',
    name: 'DocumentPrivacy',
    component: () => import('../pages/appointment/components/Privacy.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
