<template>
  <div class="reservation">
    <div class="gif">
      <img
        src="../../images/newHome/reservation/lyzx.gif"
        alt="GIF Image"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      />
      <transition name="el-fade-in">
        <div
          class="popover"
          v-show="isHover"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <div class="btn-us-contact">
            <img src="../../images/newHome/resourceCentre/qr-code.png" />
            <div class="btn-us-contact-title">扫码联系圈圈，了解更多</div>
          </div>
        </div>
      </transition>
    </div>

    <div class="reservation-card" @click="goForm">
      <img
        src="../../images/newHome/reservation/yuyue.png"
        alt=""
        class="reservation-icon"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./index.scss";
@import '@/pages/css/mobile-base.scss';
@media(max-width: $MobileWidth) {
  @import "./mobile-reservation.scss"
}
</style>
<style scoped lang="scss">
@import "./index.scss";
</style>
<script>
export default {
  name: "reservationCom",

  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
    handleMouseEnter() {
      this.isHover = true;
    },
    handleMouseLeave() {
      this.isHover = false;
    },
  },
};
</script>
